import { useEffect, useState } from 'react'
import ImageGrid from './components/ImageGrid'
import SliderContainer from './components/SliderContainer'
import AudioOn from './images/icons/volume-up.svg'
import AudioOff from './images/icons/mute.svg'
import HomeImage from '../src/images/home/home-img.png'
import './App.scss'
import Grid from '@material-ui/core/Grid'
import { Howl } from 'howler'
import LinkIcon from './images/icons/link.svg'

const music = new Howl({
  src: ['/audio/audio.mp3'],
  loop: true,
})

const App = () => {
  const [showEnterScreen, setShowEnterScreen] = useState(false)
  const [gridWidth, setGridWidth] = useState(100)
  const [audioPlaying, setAudioPlaying] = useState(false)
  const [mouseIsMoving, setMouseMoving] = useState(false)

  const enterApp = () => {
    setShowEnterScreen(false)
    setAudioPlaying(true)
    music.play()
  }

  const playAudio = () => {
    setAudioPlaying(true)
    music.play()
  }

  const pauseAudio = () => {
    setAudioPlaying(false)
    music.pause()
  }

  // @ts-ignore
  let timeout
  let whenMouseMoves = () => {
    if (showEnterScreen) return
    setMouseMoving(true)
    // @ts-ignore
    clearTimeout(timeout)
    timeout = setTimeout(() => setMouseMoving(false), 5000)
  }

  return (
    <div className="App" onMouseMove={whenMouseMoves}>
      {showEnterScreen ? (
        <div className="Overlay">
          <div className="EnterContainer">
            <Grid container spacing={5}>
              <Grid xs={3} item>
                <p className="Time">00</p>
                <p className="Unit">DAYS</p>
              </Grid>
              <Grid xs={3} item>
                <p className="Time">00</p>
                <p className="Unit">HOURS</p>
              </Grid>
              <Grid xs={3} item>
                <p className="Time">00</p>
                <p className="Unit">MINUTES</p>
              </Grid>
              <Grid xs={3} item>
                <p className="Time">00</p>
                <p className="Unit">SECONDS</p>
              </Grid>
            </Grid>
          </div>
          <div className="ExternalLinks">
            <div className="ExternalOpenseaLink">
              <div className="OpenseaLink" style={{ position: 'relative' }}>
                <a
                  href="https://etherscan.io/token/0xd6d810c6ca3a100c42ff9fd1bdd780b1a265877a"
                  target="_blank"
                  rel="no-referrer"
                >
                  <p>ETHERSCAN</p>
                </a>
                <p className="DividerBar">|</p>
                <a
                  href="https://opensea.io/collection/110-collection"
                  target="_blank"
                  rel="no-referrer"
                >
                  <p>OPENSEA</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <ImageGrid gridWidth={gridWidth} />
      <div
        className={
          mouseIsMoving ? 'SliderContainer MouseMove' : 'SliderContainer'
        }
      >
        {!showEnterScreen ? (
          <div style={{ position: 'relative', width: '100%' }}>
            <div className="SliderApp">
              <SliderContainer
                updateWidth={(val: number) => setGridWidth(val)}
              />
            </div>
            <div className="AudioApp">
              {audioPlaying ? (
                <img
                  className="Audio"
                  src={AudioOff}
                  alt="Audio off"
                  onClick={pauseAudio}
                />
              ) : (
                <img
                  className="Audio"
                  src={AudioOn}
                  alt="Audio off"
                  onClick={playAudio}
                />
              )}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {/* <audio id="audio" loop>
        <source src={soundd} type="audio/mpeg" />
      </audio> */}
    </div>
  )
}

export default App
