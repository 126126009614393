import { useState } from "react"
import Slider from "@material-ui/core/Slider"
import "./SliderContainer.scss"

type SliderContainerProps = {
  updateWidth: (v: number) => void
}

const SliderContainer = (Props: SliderContainerProps) => {
  const getMinWidth = () => {
    const innerWidth = window.innerHeight
    const singleWidth = innerWidth / 7
    const minWidth = singleWidth * 3 - 15
    return minWidth
  }

  const getState = () => {
    const innerWidth = window.innerWidth
    let final = (getMinWidth() / innerWidth) * 100

    if (innerWidth > 2000) {
      final = final / 2
    }
    return final
  }

  const [minVal, setMinVal] = useState(getState())

  const getWidth = (v: number | number[]) => {
    if (!Array.isArray(v)) {
      return v
    }
    return 100
  }

  return (
    <Slider
      defaultValue={100}
      max={100}
      min={minVal}
      onChange={(e, v) => Props.updateWidth(getWidth(v))}
    />
  )
}
export default SliderContainer
