import Grid from '@material-ui/core/Grid'
import './ImageGrid.scss'
import CloseIcon from '../../images/icons/exit.svg'
import LinkIcon from '../../images/icons/link.svg'
import { forwardRef, useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { transformIpfs } from '../../utils/index'
import Fade from '@material-ui/core/Fade'
import { Img } from 'react-image'

// CHANGE VAR FOR AMOUNT OF MINTED NFTS
const totalMinted = 21

type ImageGridProps = {
  gridWidth: number
}

interface NFTProperties {
  name: string
  image: string
  index: number
}

const Transition = forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Fade timeout={500} ref={ref} {...props} />
})

const ImageGrid = ({ gridWidth }: ImageGridProps) => {
  const [open, setOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [allNFTs, setAllNFTs] = useState<NFTProperties[]>([])

  const getMinWidth = () => {
    const innerWidth = window.innerHeight
    const singleWidth = innerWidth / 7
    const minWidth = singleWidth * 3 - 15
    return minWidth
  }

  const handleClickOpen = (image: any, i: any) => {
    setOpen(true)
    setCurrentImage(image.image)
    setCurrentIndex(image.name)
  }

  const handleClose = () => setOpen(false)

  useEffect(() => {
    let finalNFTs: NFTProperties[] = []
    for (let i = 1; i < 79; i++) {
      finalNFTs.push({
        name: i.toString(),
        image: `https://sim-110.s3.us-east-1.amazonaws.com/${i}.jpg`,
        index: i,
      })
    }
    finalNFTs.reverse()
    setAllNFTs(finalNFTs)
  }, [])

  return (
    <Grid
      container
      style={{
        margin: '0 auto',
        width: `${gridWidth}%`,
        minWidth: `${getMinWidth()}px`,
        height: '100vh',
      }}
      className="ImageGrid"
    >
      {allNFTs.map((image, i) => (
        <Grid
          item
          key={i}
          xs={4}
          style={{
            margin: '0px!important',
            padding: '0px!important',
          }}
          className="SimImage"
          onClick={() => handleClickOpen(image, i)}
        >
          <img
            src={image.image}
            alt="image"
            style={{
              width: '100%',
              height: '100%',
              minHeight: '100%',
              minWidth: '100%',
            }}
          />
        </Grid>
      ))}

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        className="Dialog"
        /** @ts-ignore */
        TransitionComponent={Transition}
      >
        <img
          src={CloseIcon}
          alt="Close"
          className="CloseIcon"
          onClick={handleClose}
        />
        <Grid container className="DialogGrid" spacing={3}>
          <Grid item xs={1} sm={3}></Grid>
          <Grid item xs={12} sm={6}>
            {currentImage !== null && currentImage !== undefined ? (
              <Img
                className="DialogImage"
                /**@ts-ignore */
                src={currentImage}
                alt="image"
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={12} sm={3} className="AboutElements">
            <div>
              <h1>{currentIndex}</h1>
              <p className="CurrentPrice">AUCTION STARTING SOON</p>
            </div>
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  )
}

export default ImageGrid
